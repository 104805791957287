import React from 'react';

import { formatCurrency } from './helpers';

const ChangeStats = ({ changeDelta, bookValue, currency }) => (
  <span>
    {formatCurrency({ quantity: changeDelta, currency })}
    <span
      className={`StockList__pill ${
        changeDelta < 0 ? 'StockList__pill--negative' : ''
      }`}
    >
      {(changeDelta / bookValue * 100).toFixed(2)}%
    </span>
  </span>
);

export default ChangeStats;
