import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from '../actions/types';

const notifications = (state = [], action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      const { message, isError, title, id } = action;
      return [{ message, isError, title, id }, ...state];

    case REMOVE_NOTIFICATION:
      return state.filter(({ id }) => id !== action.id);

    default:
      return state;
  }
};

export default notifications;
