import React from 'react';

const ListRow = ({
  allowTransactionsView,
  children,
  historyViewOpen,
  toggleTransactionsView,
  onMouseEnter,
}) => (
  <tr
    onClick={toggleTransactionsView}
    onMouseEnter={onMouseEnter}
    className={`StockList-item ${
      allowTransactionsView ? 'Stocklist-item--dropdown' : ''
    } ${historyViewOpen ? 'Stocklist-item__expanded' : ''}`}
  >
    {children}
  </tr>
);

export default ListRow;
