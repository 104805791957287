import { GET_STOCKS } from './types';
import { stockPrices } from '../helpers/apiPaths';
import { addNotification } from './notifications';
import { uniq, find } from 'lodash';
import { exchanges } from '../helpers/exchanges';

const createStock = (stocks, nextStock) => {
  const { longName, shortName, close, symbol, exchange } = nextStock;

  return {
    ...stocks,
    [symbol]: {
      currency: find(exchanges, { mic: exchange }).currency.code,
      name: longName || shortName || '',
      price: close,
    },
  };
};

export const getStocks = symbols => {
  if (!symbols.length) return { type: null };

  // older added .to stocks should be .xtse now
  // TODO: remove this once db is migrated
  const searchsymbols = uniq(symbols)
    .toString()
    .replaceAll('.TO', '.XTSE');
  return dispatch =>
    fetch(`${stockPrices}/${searchsymbols}`)
      .then(response => response.json())
      .then(result => {
        if (result.error) {
          return dispatch(
            addNotification({
              message:
                'Unable to load end of day stock prices. Try again later',
              title: 'Oops! There was a problem',
              isError: true,
            })
          );
        }
        let stocks = result.data.reduce(createStock, {});
        for (const stock in stocks) {
          if (!stocks[stock].price) {
            dispatch(
              addNotification({
                message: 'Please make sure this stock exists.',
                title: 'No data found for ' + stock,
                isError: true,
              })
            );
          }
        }

        return dispatch({
          type: GET_STOCKS,
          stocks,
        });
      })
      .catch(({ message }) =>
        dispatch(
          addNotification({
            message,
            title: 'Error fetching current stock prices',
            isError: true,
          })
        )
      );
};
