import uuid from 'uuid/v4';
import { captureMessage } from 'helpers/errorReporting';

import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from './types';

export const addNotification = options => dispatch => {
  const {
    autoclose = true,
    isError = false,
    message,
    timeout = isError ? 6000 : 3000,
    title = isError ? 'Oops, there was a problem' : 'All good',
  } = options;
  const id = uuid();

  if (isError) captureMessage(`${title}: ${message}`);

  dispatch({
    type: ADD_NOTIFICATION,
    title,
    message,
    isError,
    id,
  });

  if (autoclose) {
    setTimeout(
      () =>
        dispatch({
          type: REMOVE_NOTIFICATION,
          id,
        }),
      timeout
    );
  }
};

export const removeNotification = ({ id }) => ({
  type: REMOVE_NOTIFICATION,
  id,
});
