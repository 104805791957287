import React from 'react';

import Button from '../Button';

const Buttons = () => (
  <div>
    <Button to="/holdings/add" value="Add" className="Header-edit-holdings" />
    <Button
      to="/holdings/sell"
      value="Sell"
      secondary
      className="Header-edit-holdings"
    />
  </div>
);

export default Buttons;
