import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';

import reducers from '../reducers';

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const devTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const reduxStore = createStore(
  reducers,
  devTools
    ? compose(applyMiddleware(thunk), sentryReduxEnhancer, devTools)
    : compose(applyMiddleware(thunk), sentryReduxEnhancer)
);

export default reduxStore;
