import { UPDATE_SETTINGS } from '../actions/types';

const initialState = {
  currency: 'USD',
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SETTINGS:
      return {
        ...state,
        ...action.settings,
      };

    default:
      return state;
  }
};

export default settings;
