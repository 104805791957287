import React from 'react';

const ListTitle = props =>
  props.titles.map((title, index) => (
    <th
      key={`title-${title}`}
      className={`
        StockList-${title.toLowerCase().replace(' ', '-')}
        ${index === 4 ? 'StockList-grey' : ''}
      `}
    >
      {index === 4 ? <div className="StockList-grey-bg" /> : ''}
      {title}
    </th>
  ));

export default ListTitle;
