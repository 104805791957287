import { ADD_USER, REMOVE_USER } from './types';

import { USER_AUTH_SUCCEEDED, USER_AUTH_FAILED } from '../helpers/constants';

export const addUser = user => ({
  type: ADD_USER,
  user: { ...user, status: USER_AUTH_SUCCEEDED },
});

export const removeUser = () => ({
  type: REMOVE_USER,
  status: USER_AUTH_FAILED,
});
