import {
  GET_TRANSACTIONS,
  ADD_TRANSACTION,
  DELETE_TRANSACTION,
} from 'actions/types';

import { parseDateAsInteger } from 'helpers/dates';

const transactions = (state = [], action) => {
  switch (action.type) {
    case GET_TRANSACTIONS:
      return action.transactions;

    case ADD_TRANSACTION:
      return [...state, action.transaction].sort(
        (a, b) =>
          parseDateAsInteger(a.purchaseDate) -
          parseDateAsInteger(b.purchaseDate)
      );

    case DELETE_TRANSACTION:
      return state.filter(({ id }) => id !== action.transaction.id);

    default:
      return state;
  }
};

export default transactions;
