import * as Sentry from '@sentry/react';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://59958995f5344166b115e86ac468d4a5@sentry.io/1308255',
    release: 'finance-app@' + process.env.REACT_APP_COMMIT_REF,
    environment: process.env.REACT_APP_CONTEXT,
  });
}

export const captureMessage = message =>
  process.env.NODE_ENV === 'production'
    ? Sentry.captureMessage(message)
    : console.error(message);
