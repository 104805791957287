import 'helpers/errorReporting';
import 'helpers/firebase';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';

import App from 'views/App';
import Performance from 'views/Performance';
import Holdings from 'views/Holdings';
import Auth from 'views/Auth';
import PrivacyPolicy from 'views/documents/PrivacyPolicy';
import TermsOfService from 'views/documents/TermsOfService';
import reduxStore from 'helpers/reduxStore';
import { authPath, tosPath, privacyPath } from 'helpers/paths';

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={'An error has occured'}>
    <Provider store={reduxStore}>
      <BrowserRouter>
        <App>
          <Switch>
            <Route path="/performance" exact component={Performance} />
            <Route path="/holdings" component={Holdings} />
            <Route path={privacyPath} component={PrivacyPolicy} />
            <Route path={tosPath} component={TermsOfService} />
            <Route path={authPath} component={Auth} />
          </Switch>
        </App>
      </BrowserRouter>
    </Provider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);
