import reduxStore from '../reduxStore';
import { addUser, removeUser } from '../../actions';
import { captureMessage } from '../errorReporting';
import { setUser, trackEvent } from 'helpers/analytics';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

import 'firebase/analytics';

firebase.initializeApp({
  apiKey: 'AIzaSyB9bdRNxwG2hC90EdhOrs2R_YDLarZFEmM',
  authDomain: 'stockwallet.io',
  databaseURL: 'https://finance-app-57a0a.firebaseio.com',
  projectId: 'finance-app-57a0a',
  storageBucket: '',
  messagingSenderId: '319157579559',
  appId: '1:319157579559:web:c978a8a3d668be66d0c302',
  measurementId: 'G-3Y8H0KLH8E',
});

firebase.analytics();

const firebaseDb = firebase.firestore();

// use local db
// if (window.location.hostname === 'localhost') {
//   firebaseDb.settings({
//     host: 'localhost:8080',
//     ssl: false,
//   });
// }

firebase.auth().useDeviceLanguage();

firebase.auth().onAuthStateChanged(user => {
  if (user) {
    reduxStore.dispatch(
      addUser({
        name: user.displayName,
        email: user.email,
        avatarUrl: user.photoURL,
        uid: user.uid,
      })
    );
    setUser(user.uid);
    // window.location.assign('/performance');
  } else {
    reduxStore.dispatch(removeUser());
    // trackEvent('logged_out');
    // window.location.assign('/');
  }
});

firebase
  .auth()
  .getRedirectResult()
  .then(result => {
    if (result.user === null && result.credential === null) {
      trackEvent('failed login');
      return null;
    }
    trackEvent('login');
    return result;
  });

const authenticateUserWithGoogle = () => {
  const provider = new firebase.auth.GoogleAuthProvider();

  provider.setCustomParameters({
    login_hint: 'user@example.com',
  });

  firebase
    .auth()
    .signInWithPopup(provider)
    .catch(error => captureMessage(error));
};

const unauthenticateUserWithGoogle = () => firebase.auth().signOut();

export { authenticateUserWithGoogle, unauthenticateUserWithGoogle, firebaseDb };
