import './LoadingSpinner.css';

import React from 'react';

export default class LoadingPlaceholder extends React.Component {
  state = {
    visible: false,
  };

  componentDidMount() {
    this.timeout = setTimeout(this.setState.bind(this, { visible: true }), 300);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render() {
    return (
      <div
        className={`Loading-spinner__container ${
          this.props.withHeader ? 'Loading-spinner--with-header' : ''
        }`}
      >
        <div
          className={`Loading-spinner ${
            this.state.visible ? 'Loading-spinner--visible' : ''
          }`}
        />
      </div>
    );
  }
}
