import './Notifications.css';

import React from 'react';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { SuccessIcon, ErrorIcon, CloseIcon } from '../Icons';
import { removeNotification } from '../../actions';

const Notification = ({ isError, title, message, onClick }) => (
  <div className="Notification" onClick={onClick}>
    <span className="NotificationIcon">
      {isError ? <ErrorIcon /> : <SuccessIcon />}
    </span>

    <h3 className="NotificationTitle">{title}</h3>
    <p className="NotificationMessage">{message}</p>

    <span className="NotificationClose">
      <CloseIcon />
    </span>
  </div>
);

const Notifications = ({ notifications, dismissNotification }) => (
  <div className="Notifications">
    <TransitionGroup>
      {notifications.map(notification => (
        <CSSTransition
          key={notification.id}
          timeout={300}
          classNames="Notification"
        >
          <Notification
            onClick={() => dismissNotification(notification)}
            {...notification}
          />
        </CSSTransition>
      ))}
    </TransitionGroup>
  </div>
);

const mapStateToProps = state => ({ notifications: state.notifications });
const mapDispatchToProps = dispatch => ({
  dismissNotification: notification =>
    dispatch(removeNotification(notification)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
