import { UPDATE_SETTINGS } from './types';

import { firebaseDb } from '../helpers/firebase';
import { addNotification } from './notifications';

const settingsCollection = 'settings';

export const updateSettings = (userId, { currency }) => dispatch => {
  dispatch({
    type: UPDATE_SETTINGS,
    settings: { currency },
  });

  firebaseDb
    .collection(settingsCollection)
    .doc(userId)
    .set({ currency }, { merge: true })
    .catch(({ message }) =>
      dispatch(
        addNotification({
          message,
          title: 'Error updating settings',
          isError: true,
        })
      )
    );
};

export const getSettings = userId => dispatch =>
  firebaseDb
    .collection(settingsCollection)
    .doc(userId)
    .get()
    .then(doc => doc.data())
    .then(settings => dispatch({ type: UPDATE_SETTINGS, settings }))
    .catch(({ code, message }) => {
      if (code !== 'permission-denied') {
        dispatch(
          addNotification({
            message,
            title: 'Error getting settings',
            isError: true,
          })
        );
      }
    });
