import './StockList.css';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ListRow from './ListRow';
import ListCell from './ListCell';
import ListFooter from './ListFooter';
import ListTitle from './ListTitle';
import EmptyState from './EmptyState';
import Graph from 'components/Graph';
import HoldingTransactions from './HoldingTransactions';
import LoadingSpinner from 'components/LoadingSpinner';
import { HOLDING, calculateOpenHistoryViews } from './helpers';
import GraphPlaceholder from 'components/Graph/GraphPlaceholder';

export default class StockList extends Component {
  static propTypes = {
    allowTransactionsView: PropTypes.bool,
    columnTitles: PropTypes.array.isRequired,
    holdings: PropTypes.object,
    stocks: PropTypes.object,
    view: PropTypes.string.isRequired,
  };

  static defaultProps = {
    allowTransactionsView: false,
    holdings: {},
    stocks: [],
  };

  state = {
    loading: true,
    highlighted: undefined,
    openHistoryViews: [],
  };

  componentDidMount() {
    const { getTransactions, user } = this.props;
    getTransactions(user.uid, () => this.setState({ loading: false }));
  }

  toggleTransactionsView = symbol => {
    if (this.props.allowTransactionsView === false) {
      return;
    }

    const openHistoryViews = calculateOpenHistoryViews({
      ...this.state,
      symbol,
    });

    this.setState({ openHistoryViews });
  };

  historyViewOpen = holdingSymbol =>
    this.state.openHistoryViews.indexOf(holdingSymbol) > -1;

  render() {
    const {
      holdings,
      columnTitles,
      stocks,
      allowTransactionsView,
      view,
    } = this.props;

    if (this.state.loading) {
      return <LoadingSpinner withHeader={true} />;
    }

    if (!Object.keys(holdings).length && !this.state.loading) {
      return <EmptyState />;
    }

    return (
      <div>
        {view === 'PERFORMANCE_VIEW' && Object.keys(stocks).length > 0 && (
          <Graph
            holdings={holdings}
            stocks={stocks}
            highlighted={this.state.highlighted}
            transactions={this.props.transactions}
          />
        )}
        {view === 'PERFORMANCE_VIEW' && Object.keys(stocks).length === 0 && (
          <GraphPlaceholder />
        )}
        <table className={'StockList'}>
          <thead>
            <tr className="StockList-header">
              <ListTitle titles={columnTitles} />
            </tr>
          </thead>

          <tbody onMouseLeave={() => this.setState({ highlighted: null })}>
            {Object.keys(holdings).map(holdingSymbol => [
              <ListRow
                key={holdingSymbol}
                allowTransactionsView={allowTransactionsView}
                historyViewOpen={this.historyViewOpen(holdingSymbol)}
                toggleTransactionsView={() =>
                  this.toggleTransactionsView(holdingSymbol)
                }
                onMouseEnter={() =>
                  this.setState({ highlighted: holdingSymbol })
                }
              >
                <ListCell
                  holding={holdings[holdingSymbol]}
                  titles={columnTitles}
                  stock={stocks[holdingSymbol]}
                  type={HOLDING}
                />
              </ListRow>,
              this.historyViewOpen(holdingSymbol) === true && (
                <HoldingTransactions
                  key={`${holdingSymbol}-transactions`}
                  holdingSymbol={holdingSymbol}
                  {...this.props}
                />
              ),
            ])}
          </tbody>

          <tfoot>
            <ListFooter holdings={holdings} type={view} stocks={stocks} />
          </tfoot>
        </table>
      </div>
    );
  }
}
