import React from 'react';
import { connect } from 'react-redux';

import { getHoldings } from '../../helpers/holdings';

const mapStateToProps = state => ({
  holdings: getHoldings(state.transactions),
  stocks: state.stocks,
});

const StockDropdown = ({ holdings, stocks, onChange }) => (
  <select
    defaultValue=""
    className="Modal__input"
    onChange={event => onChange('symbol', event)}
  >
    <option disabled value="">
      Select a holding
    </option>

    {Object.keys(holdings).map(symbol => (
      <option key={symbol} value={symbol}>
        {symbol} &ndash; {stocks[symbol] && stocks[symbol].name}
      </option>
    ))}
  </select>
);

export default connect(mapStateToProps)(StockDropdown);
