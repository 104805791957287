import React from 'react';
import PropTypes from 'prop-types';

import LoadingPlaceholder from '../../components/LoadingPlaceholder';
import {
  HOLDING,
  TRANSACTION,
  getCellValuesForHolding,
  getCellValuesForTransaction,
} from './helpers';

const getCellValuesByType = {
  [HOLDING]: getCellValuesForHolding,
  [TRANSACTION]: getCellValuesForTransaction,
};

const ListCell = ({
  type,
  holding,
  titles,
  stock,
  transaction,
  deleteTransaction,
}) => {
  const getCellValues = getCellValuesByType[type];
  const rawCellData = {
    [HOLDING]: holding,
    [TRANSACTION]: transaction,
  }[type];

  const cellData = getCellValues(rawCellData);

  return titles.map((title, index) => {
    if (Object.keys(cellData).indexOf(title) === -1) {
      console.error(`invalid type "${title}" passed to Stock List`);
      return null;
    }

    const lastColumnStyle = index === 4 ? 'StockList-grey' : '';
    const holdingListTitle = `
      StockList-${title.toLowerCase().replace(' ', '-')}
    `;

    const cellValue = cellData[title]({ stock });

    return (
      <td
        key={title}
        title={title === 'Symbol' ? cellValue : undefined}
        className={`StockList-cell ${holdingListTitle} ${lastColumnStyle}`}
      >
        {cellValue === undefined ? <LoadingPlaceholder /> : cellValue}
        {title === 'Name' && deleteTransaction && (
          <button
            className="StockList-cell__Delete"
            onClick={() => deleteTransaction(transaction)}
          >
            Delete Transaction
          </button>
        )}
      </td>
    );
  });
};

ListCell.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ListCell;
