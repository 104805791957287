import React from 'react';

import Button from '../../components/Button';
import emptyStateIcon from '../../images/empty-state.svg';

const EmptyState = () => (
  <div className="Stocklist__empty">
    <img src={emptyStateIcon} alt="No holdings" />
    <p className="Stocklist__empty-text">You don&rsquo;t have any holdings.</p>
    <Button to="/holdings/add" value="Add a Holding" />
  </div>
);

export default EmptyState;
