import './LoadingPlaceholder.css';

import React from 'react';

const randomWidth = () => Math.floor(Math.random() * (80 - 60) + 60) + '%';

const LoadingPlaceholder = () => (
  <div className="Loading-placeholder" style={{ width: randomWidth() }} />
);

export default LoadingPlaceholder;
