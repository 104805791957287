import './Footer.css';

import React from 'react';
import Obfuscate from 'react-obfuscate';
import { Link } from 'react-router-dom';

import { trackEvent } from 'helpers/analytics';

const Footer = ({ inApp }) => (
  <footer className="Home__footer">
    <div className="Home__footer__contact">
      Bugs? Feature requests?&nbsp;
      <Obfuscate
        className="Home__footer__email"
        email="hello@stockwallet.io"
        onClick={() => trackEvent('Clicked contact email link')}
        headers={{
          subject: 'Stockwallet bug/feature request',
        }}
      >
        Get in touch
      </Obfuscate>
    </div>
    {!inApp && (
      <div className="Home__footer__links">
        <Link to="/privacy-policy">Privacy policy</Link>
        {'   ·   '}
        <Link to="/terms-of-service">Terms of service</Link>
      </div>
    )}
  </footer>
);

export default Footer;
