import './Header.css';

import logo from '../../images/stockwallet-logo.svg';
import IconPerformance from '../../images/icon-performance.svg';
import IconHoldings from '../../images/icon-holdings.svg';

import React from 'react';
import { Route, Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Buttons from './Buttons';
import Avatar from './Avatar';
import { stockListViews } from '../../helpers/paths';
import { supportedCurrencies } from '../../helpers/convertCurrency';
import { updateSettings } from '../../actions/settings';

const mapStateToProps = state => ({
  user: state.user,
  selectedCurrency: state.settings.currency,
});

const mapDispatchToProps = dispatch => ({
  updateCurrency: (userId, currency) =>
    dispatch(updateSettings(userId, { currency })),
});

const RouteIcon = ({ label }) => {
  const img = label === 'Performance' ? IconPerformance : IconHoldings;
  return <img src={img} alt={label} className="Header-menu-icon" />;
};

const createNavLinks = ({ name, url }) => (
  <li key={name}>
    <Route
      path={url}
      exact={url === '/'}
      children={({ match }) => (
        <Link to={url} data-active={match !== null}>
          <RouteIcon label={name} />
          {name}
        </Link>
      )}
    />
  </li>
);

const Header = ({ user, selectedCurrency, updateCurrency }) => (
  <header className="Header">
    <h1 className="Header-title">
      <img src={logo} alt="Stockwallet.io" />
    </h1>

    <nav className="Header-menu">
      <ul>{stockListViews.map(createNavLinks)}</ul>
    </nav>

    <div className="Header-buttons">
      <Route path={'/holdings'} render={Buttons} />
    </div>

    {user && (
      <Avatar
        user={user}
        changeCurrency={currency => updateCurrency(user.uid, currency)}
        selectedCurrency={selectedCurrency}
        supportedCurrencies={supportedCurrencies}
      />
    )}
  </header>
);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Header)
);
