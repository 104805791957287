import React from 'react';

import { PERFORMANCE_VIEW, HOLDINGS_VIEW } from 'helpers/constants';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

import reduxStore from 'helpers/reduxStore';
import { formatCurrency } from './helpers';
import convertCurrency from 'helpers/convertCurrency';
import ChangeStats from './ChangeStats';

const convertToQuoteCurrency = (amount, from) =>
  from ? convertCurrency({ amount, from }) : 0;

const sumStockValue = (sum, current) => sum + current;
const calculateStockValue = (stocks, stock) =>
  stock && stocks[stock.symbol] && stocks[stock.symbol].currency
    ? convertToQuoteCurrency(
        stocks[stock.symbol].price * stock.quantity,
        stocks[stock.symbol].currency
      )
    : 0;

const ListFooter = ({ type, holdings, stocks }) => {
  const marketValue = Object.keys(holdings)
    .map(symbol => calculateStockValue(stocks, holdings[symbol]))
    .reduce(sumStockValue, 0);

  const bookValue =
    Object.keys(stocks).length > 0 &&
    Object.keys(holdings)
      .map(
        symbol =>
          stocks[symbol] &&
          holdings[symbol] &&
          convertToQuoteCurrency(
            holdings[symbol].totalCost,
            stocks[symbol].currency
          )
      )
      .reduce(sumStockValue, 0);

  switch (type) {
    case HOLDINGS_VIEW:
      return (
        <tr className="Stocklist-footer">
          <td />
          <td />
          <td />
          <td />
          <td className="StockList-grey StockList-all-total">
            {bookValue ? (
              formatCurrency({
                quantity: bookValue,
                currency: reduxStore.getState().settings.currency,
              })
            ) : (
              <LoadingPlaceholder />
            )}
          </td>
        </tr>
      );

    case PERFORMANCE_VIEW:
      return (
        <tr className="Stocklist-footer">
          <td />
          <td />
          <td className="StockList-all-total StockList-cell">
            {bookValue ? (
              formatCurrency({
                quantity: bookValue,
                currency: reduxStore.getState().settings.currency,
              })
            ) : (
              <LoadingPlaceholder />
            )}
          </td>
          <td className="StockList-all-total StockList-cell">
            {marketValue ? (
              formatCurrency({
                quantity: marketValue,
                currency: reduxStore.getState().settings.currency,
              })
            ) : (
              <LoadingPlaceholder />
            )}
          </td>
          <td className="StockList-grey StockList-all-total">
            {marketValue ? (
              <ChangeStats
                changeDelta={marketValue - bookValue}
                bookValue={bookValue}
                currency={reduxStore.getState().settings.currency}
              />
            ) : (
              <LoadingPlaceholder />
            )}
          </td>
        </tr>
      );

    default:
      console.error('Invaid <ListFooter /> type');
      return null;
  }
};

export default ListFooter;
