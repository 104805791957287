import { combineReducers } from 'redux';

import notifications from './notifications';
import settings from './settings';
import stocks from './stocks';
import transactions from './transactions';
import user from './user';

export default combineReducers({
  notifications,
  settings,
  stocks,
  transactions,
  user,
});
