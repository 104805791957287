import React from 'react';

import ListCell from './ListCell';
import { TRANSACTION } from './helpers';

const HoldingTransactions = ({
  holdingSymbol,
  columnTitles,
  stocks,
  transactions,
  deleteTransaction,
}) =>
  transactions
    .filter(({ symbol }) => symbol === holdingSymbol)
    .map((transaction, i) => (
      <tr key={i} className="StockList-transactions">
        <ListCell
          transaction={transaction}
          titles={columnTitles}
          stock={stocks[holdingSymbol]}
          type={TRANSACTION}
          deleteTransaction={deleteTransaction}
        />
      </tr>
    ));

export default HoldingTransactions;
