import React from 'react';

export const ErrorIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="8" fill="#F64B4B" />
    <path
      d="M10.7578 5.52344H13.2031V8.44531L12.5703 13.8203H11.4062L10.7578 8.44531V5.52344ZM10.8125 14.7422H13.1406V17H10.8125V14.7422Z"
      fill="white"
    />
  </svg>
);

export const SuccessIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="8" fill="#1F5EEB" />
    <path d="M6.5 12L10.5 16L17.5 6.5" stroke="white" strokeWidth="2" />
  </svg>
);

export const CloseIcon = () => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path opacity="0.3" d="M10 1L1 10M1 1L10 10" stroke="black" />
  </svg>
);
