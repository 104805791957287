import { GET_TRANSACTIONS, ADD_TRANSACTION, DELETE_TRANSACTION } from './types';
import { addNotification } from './notifications';
import { firebaseDb } from '../helpers/firebase';

const transactionsCollection = 'transactions';

export const getTransactions = userId => dispatch =>
  firebaseDb
    .collection(transactionsCollection)
    .where('userId', '==', userId)
    .orderBy('purchaseDate', 'desc')
    .get()
    .then(querySnapshot =>
      querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      }))
    )
    .then(transactions => dispatch({ type: GET_TRANSACTIONS, transactions }))
    .catch(({ message }) =>
      dispatch(
        addNotification({
          message,
          title: 'Error getting transactions',
          isError: true,
        })
      )
    );

export const addTransaction = transaction => dispatch =>
  firebaseDb
    .collection(transactionsCollection)
    .add(transaction)
    .then(({ id }) => {
      dispatch({
        type: ADD_TRANSACTION,
        transaction: { ...transaction, id },
      });

      dispatch(
        addNotification({
          title: `${transaction.quantity > 0 ? 'Added' : 'Sold'} ${
            transaction.symbol
          }`,
          message: 'All good',
        })
      );
    })
    .catch(({ message }) =>
      dispatch(
        addNotification({
          message,
          title: `Error ${transaction.quantity > 0 ? 'adding' : 'selling'} ${
            transaction.symbol
          }`,
          isError: true,
        })
      )
    );

export const deleteTransaction = transaction => dispatch =>
  firebaseDb
    .collection(transactionsCollection)
    .doc(transaction.id)
    .get()
    .then(({ ref }) => {
      dispatch({ type: DELETE_TRANSACTION, transaction });

      ref.delete().catch(({ message }) => {
        // add back in the transaction that was optimistically removed
        dispatch({ type: ADD_TRANSACTION, transaction });

        // notify user of the error
        dispatch(
          addNotification({
            message,
            title: `Error deleting ${transaction.symbol}`,
            isError: true,
          })
        );
      });
    })
    .catch(({ message }) =>
      dispatch(
        addNotification({
          message,
          title: `Error deleting ${transaction.symbol}`,
          isError: true,
        })
      )
    );
