import { connect } from 'react-redux';

import { getStocks, getTransactions, deleteTransaction } from '../../actions';
import { getHoldings } from '../../helpers/holdings';
import StockList from './StockList';

const mapStateToProps = state => ({
  holdings: getHoldings(state.transactions),
  stocks: state.stocks,
  transactions: state.transactions,
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  deleteTransaction: transaction => dispatch(deleteTransaction(transaction)),
  getTransactions: (userUid, onDone) =>
    dispatch(getTransactions(userUid)).then(({ transactions }) => {
      const symbols = transactions.map(({ symbol }) => symbol);
      dispatch(getStocks(symbols));
      onDone();
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(StockList);
