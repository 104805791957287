import './Graph.css';

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AreaChart, Area, XAxis, Tooltip, ResponsiveContainer } from 'recharts';
import moment from 'moment';
import convertCurrency from 'helpers/convertCurrency';
import { captureMessage } from 'helpers/errorReporting';
import GraphPlaceholder from './GraphPlaceholder';
import _ from 'lodash';

const API = `https://47di7s8yjh.execute-api.us-east-1.amazonaws.com/prod/stock-price-history-v2/`;

// store api response in localStorage for this length before invalidating
const LOCAL_CACHE_HOURS = 24;

function sameMonth(date1, date2) {
  // check if same month and year by comparing the first 7 chars.
  // Dates must be in isoString format
  return date1.substr(0, 7) === date2.substr(0, 7);
}

const formatNumber = number =>
  number === undefined
    ? ''
    : '$' + number.toFixed(0).replace(/\B(?=(?:\d{3})+(?!\d))/g, ',');

export default class Graph extends Component {
  static propTypes = {
    stocks: PropTypes.object,
    holdings: PropTypes.object,
    transactions: PropTypes.array,
  };

  state = {
    data: [],
    isLoading: true,
  };

  addGraphData(holding, stockHistory) {
    if (!stockHistory) {
      return captureMessage(
        `No history data to add to graph for ${holding.symbol}`
      );
    }
    if (!this.props.stocks[holding.symbol]) return false;
    const currency = this.props.stocks[holding.symbol].currency;

    let data = [...this.state.data];
    const dateToday = new Date().toISOString();
    stockHistory.forEach(item => {
      // if its current month, skip
      if (!item) return false;
      if (item && sameMonth(item.date, dateToday)) return false;

      // check how much you actually hold of the stock and add up quantities
      const quantity = this.props.transactions
        .map(transaction => {
          if (
            transaction.symbol === holding.symbol &&
            moment(transaction.purchaseDate).isBefore(item.date)
          ) {
            return transaction.quantity;
          } else {
            return 0;
          }
        })
        .reduce((prev, current) => prev + current);

      // TODO: we should convert state.data to an object for easier manipulation
      // and then convert to array just for the graph lib with `Object.values()`

      // If date already in array, add to it. Otherwise, create it.
      let target = data.find(el =>
        sameMonth(
          moment(el.name).toISOString(),
          moment(item.date).toISOString()
        )
      );

      if (target) {
        target[holding.symbol] = convertCurrency({
          from: currency,
          amount: quantity * item.value,
        });
      } else {
        // add a this month's data point to graph if it doesnt exist
        let newGraphMonth = { name: moment(item.date).toISOString() };
        newGraphMonth[holding.symbol] = convertCurrency({
          from: currency,
          amount: quantity * item.value,
        });
        data = _.sortBy([newGraphMonth, ...data], 'name');
      }
    });

    return this.setState({ data });
  }

  ParseData(data, symbol) {
    if (data.errorMessage || !data.chart.result) {
      captureMessage(`Failed to load history data for ${symbol}`);
      return false;
    }
    const root = data.chart.result[0];
    const prices =
      root.indicators.adjclose[0].adjclose &&
      root.indicators.adjclose[0].adjclose.slice(0, 25).reverse();
    const dates = root.timestamp && root.timestamp.slice(0, 25).reverse();

    if (!prices || !dates) {
      captureMessage(`No data available for ${symbol}`);
      return false;
    }

    return dates.map((timestamp, index) => {
      const date = new Date(timestamp * 1000).toISOString();
      const todayDate = new Date().toISOString();
      if (
        date.substr(0, 10) === todayDate.substr(0, 10) ||
        moment(date).isBefore(moment().subtract(24, 'months'), 'month')
      ) {
        return false;
      }

      return {
        date,
        value: prices[index],
      };
    });
  }

  cacheIsValid(symbol) {
    const data = localStorage.getItem(`graphData-${symbol}`);
    const dataDate = localStorage.getItem(`graphDataDate-${symbol}`);
    const expireTime = moment().subtract(LOCAL_CACHE_HOURS, 'hours');
    if (!data || moment(dataDate).isBefore(expireTime)) {
      return false;
    } else {
      return true;
    }
  }

  async getHistory(holdings) {
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

    for (let symbol of Object.keys(holdings)) {
      if (holdings.hasOwnProperty(symbol)) {
        if (this.cacheIsValid(symbol)) {
          const data = localStorage.getItem(`graphData-${symbol}`);
          await this.addGraphData(holdings[symbol], JSON.parse(data));
        } else {
          // history api uses .to instead of .xtse
          const res = await fetch(API + symbol.replaceAll('.XTSE', '.TO'));
          const body = await res.json();
          const stockHistory = this.ParseData(body, symbol);
          localStorage.setItem(
            `graphData-${symbol}`,
            JSON.stringify(stockHistory)
          );
          localStorage.setItem(
            `graphDataDate-${symbol}`,
            moment().toISOString()
          );
          await this.addGraphData(holdings[symbol], stockHistory);
          await sleep(400);
        }
      }
    }
    this.setState({ isLoading: false });
  }

  // use realtime price for the current month
  addCurrentMonth(stocks, holdings) {
    //noop
    // todo: fix this
    // return {};
    // let currentMonth = { name: moment().toISOString() };
    // for (let symbol in stocks) {
    //   currentMonth[symbol] = convertCurrency({
    //     from: stocks[symbol].currency,
    //     amount: holdings[symbol].quantity * stocks[symbol].price,
    //   });
    // }
    // return currentMonth;
  }

  componentDidMount() {
    this.setState(
      {
        data: [this.addCurrentMonth(this.props.stocks, this.props.holdings)],
      },
      () => this.getHistory(this.props.holdings)
    );
  }

  renderTooltip(tip) {
    let total = 0;
    if (!tip.active || !tip.payload) return null;
    const values = tip.payload.map(e => {
      total += e.value;
      return (
        <div key={e.dataKey}>
          {e.dataKey} &ndash; {formatNumber(e.value)}
        </div>
      );
    });
    return (
      <div className="Graph-tooltip">
        <h4 className="Graph-tooltip-header">
          {moment(tip.label.substr(0, 10)).format('MMM YYYY')}
        </h4>
        {values}
        <strong>Total &ndash; {formatNumber(total)}</strong>
      </div>
    );
  }

  render() {
    const { holdings, highlighted } = this.props;
    if (this.state.isLoading) {
      return <GraphPlaceholder />;
    }
    return (
      <div className="Graph-wrapper">
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart data={this.state.data}>
            <Tooltip
              cursor={{ stroke: '#D6E2FF', strokeWidth: 1 }}
              content={this.renderTooltip}
            />
            <XAxis
              hide
              padding={{ left: 5, right: 5 }}
              axisLine={false}
              tickLine={false}
              interval="preserveStartEnd"
              minTickGap={20}
              tickFormatter={date => moment(date).format('MMM')}
              tickMargin={10}
              dataKey="name"
              style={{ fontSize: '0.8em', fill: '#111' }}
            />
            {Object.keys(holdings).map((symbol, index) => {
              const isActive = highlighted && highlighted === symbol;
              const isLast = index === Object.keys(holdings).length - 1;
              return (
                <Area
                  key={symbol}
                  type="monotone"
                  dataKey={symbol}
                  stackId="1"
                  stroke={isLast ? '#1F5EED' : '#DFE9FF'}
                  strokeOpacity={1}
                  strokeWidth={isActive && !isLast ? 0 : 2}
                  fill={'#D6E2FF'}
                  fillOpacity={isActive ? 1 : 0.2}
                  dot={
                    isLast
                      ? {
                          stroke: '#fff',
                          fill: '#1F5EED',
                          fillOpacity: 1,
                          strokeWidth: 2,
                          r: 4,
                        }
                      : {
                          strokeWidth: 0,
                          r: 3,
                          fillOpacity: 0,
                          fill: '#D6E2FF',
                        }
                  }
                  activeDot={{
                    fillOpacity: 0,
                    stroke: 0,
                  }}
                />
              );
            })}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
