import React from 'react';

import Button from 'components/Button';
import iconDropdown from 'images/down-arrow.svg';
import userPlaceholder from 'images/user-placeholder.svg';
import { unauthenticateUserWithGoogle } from 'helpers/firebase';
import { trackEvent } from 'helpers/analytics';

class Avatar extends React.Component {
  state = {
    currencySwitcherOpen: false,
  };

  renderSwitcher() {
    const {
      selectedCurrency,
      supportedCurrencies,
      changeCurrency,
    } = this.props;
    return (
      <div className="Header__user-popup">
        {supportedCurrencies.map(currency => (
          <div
            className={`Header__user-popup-list-item ${
              selectedCurrency === currency
                ? 'Header__user-popup-list-item-active'
                : ''
            }`}
            onClick={() => {
              trackEvent('Changed currency');
              this.setState({ currencySwitcherOpen: false });
              changeCurrency(currency);
            }}
            key={currency}
          >
            {currency}
          </div>
        ))}
      </div>
    );
  }

  renderPopup() {
    const { user, selectedCurrency } = this.props;
    return (
      <div className="Header__user-popup Header__user-popup--padded">
        <h4 className="Header__user-popup-text">{user.name}</h4>
        <p className="Header__user-popup-text">{user.email}</p>

        <div
          className="Header__user-popup-section"
          onClick={() => {
            trackEvent('Opened currency switcher');
            this.setState({ currencySwitcherOpen: true });
          }}
        >
          <h5 className="Header__user-popup-title">Currency</h5>
          <strong>{selectedCurrency}</strong>
          <span style={{ float: 'right', opacity: 0.4 }}>
            Change &nbsp;&rarr;
          </span>
        </div>

        <Button
          className="Header__user-logout Button--full-width"
          secondary
          onClick={unauthenticateUserWithGoogle}
          value="Logout"
        />
      </div>
    );
  }

  render() {
    return (
      <div className="Header__user" tabIndex="1">
        <img
          className="Header__user-img"
          src={this.props.user.avatarUrl || userPlaceholder}
          alt="avatar"
          width={30}
        />

        <img
          className="Header__user-arrow"
          src={iconDropdown}
          alt="User menu dropdown"
        />

        {this.state.currencySwitcherOpen
          ? this.renderSwitcher()
          : this.renderPopup()}
      </div>
    );
  }
}

export default Avatar;
