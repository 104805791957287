import React from 'react';
import placeholder from './graph_placeholder.svg';

const GraphPlaceholder = () => (
  <div style={{ display: 'flex' }} className="Graph-wrapper">
    <img
      className="Graph-placeholder"
      src={placeholder}
      alt="Loading graph... "
    />
  </div>
);

export default GraphPlaceholder;
