export const exchanges = [
  {
    name: 'NASDAQ Stock Exchange',
    acronym: 'NASDAQ',
    mic: 'XNAS',
    country: 'USA',
    country_code: 'US',
    city: 'New York',
    website: 'www.nasdaq.com',
    timezone: {
      timezone: 'America/New_York',
      abbr: 'EST',
      abbr_dst: 'EDT',
    },
    currency: {
      code: 'USD',
      symbol: '$',
      name: 'US Dollar',
    },
  },
  {
    name: 'New York Stock Exchange',
    acronym: 'NYSE',
    mic: 'XNYS',
    country: 'USA',
    country_code: 'US',
    city: 'New York',
    website: 'www.nyse.com',
    timezone: {
      timezone: 'America/New_York',
      abbr: 'EST',
      abbr_dst: 'EDT',
    },
    currency: {
      code: 'USD',
      symbol: '$',
      name: 'US Dollar',
    },
  },
  {
    name: 'NYSE ARCA',
    acronym: 'NYSEARCA',
    mic: 'ARCX',
    country: 'USA',
    country_code: 'US',
    city: 'New York',
    website: 'www.nyse.com',
    timezone: {
      timezone: 'America/New_York',
      abbr: 'EST',
      abbr_dst: 'EDT',
    },
    currency: {
      code: 'USD',
      symbol: '$',
      name: 'US Dollar',
    },
  },
  {
    name: 'OTC Markets',
    acronym: '',
    mic: 'OTCM',
    country: 'USA',
    country_code: 'US',
    city: 'New York',
    website: 'www.otcmarkets.com',
    timezone: {
      timezone: 'America/New_York',
      abbr: 'EST',
      abbr_dst: 'EDT',
    },
    currency: {
      code: 'USD',
      symbol: '$',
      name: 'US Dollar',
    },
  },
  {
    name: 'Buenos Aires Stock Exchange',
    acronym: 'BCBA',
    mic: 'XBUE',
    country: 'Argentina',
    country_code: 'AR',
    city: 'Buenos Aires',
    website: 'www.bcba.sba.com.ar',
    timezone: {
      timezone: 'America/Argentina/Buenos_Aires',
      abbr: '-03',
      abbr_dst: '-03',
    },
    currency: {
      code: 'ARS',
      symbol: 'AR$',
      name: 'Argentine Peso',
    },
  },
  {
    name: 'Bahrein Bourse',
    acronym: 'BSE',
    mic: 'XBAH',
    country: 'Bahrain',
    country_code: 'BH',
    city: 'Manama',
    website: 'www.bahrainbourse.com.bh',
    timezone: {
      timezone: 'Asia/Bahrain',
      abbr: '+03',
      abbr_dst: '+03',
    },
    currency: {
      code: 'BHD',
      symbol: 'BD',
      name: 'Bahraini Dinar',
    },
  },
  {
    name: 'Euronext Brussels',
    acronym: 'Euronext',
    mic: 'XBRU',
    country: 'Belgium',
    country_code: 'BE',
    city: 'Brussels',
    website: 'www.euronext.com',
    timezone: {
      timezone: 'Europe/Brussels',
      abbr: 'CET',
      abbr_dst: 'CEST',
    },
    currency: {
      code: 'EUR',
      symbol: '€',
      name: 'Euro',
    },
  },
  {
    name: 'B3 - Brasil Bolsa Balcão S.A',
    acronym: 'Bovespa',
    mic: 'BVMF',
    country: 'Brazil',
    country_code: 'BR',
    city: 'Sao Paulo',
    website: 'www.bmfbovespa.com.br',
    timezone: {
      timezone: 'America/Sao_Paulo',
      abbr: '-03',
      abbr_dst: '-03',
    },
    currency: {
      code: 'BRL',
      symbol: 'R$',
      name: 'Brazilian Real',
    },
  },
  {
    name: 'Toronto Stock Exchange',
    acronym: 'TSX',
    mic: 'XTSE',
    country: 'Canada',
    country_code: 'CA',
    city: 'Toronto',
    website: 'www.tse.com',
    timezone: {
      timezone: 'America/Toronto',
      abbr: 'EST',
      abbr_dst: 'EDT',
    },
    currency: {
      code: 'CAD',
      symbol: 'CA$',
      name: 'Canadian Dollar',
    },
  },
  {
    name: 'Canadian Securities Exchange',
    acronym: 'CNSX',
    mic: 'XCNQ',
    country: 'Canada',
    country_code: 'CA',
    city: 'Toronto',
    website: 'www.cnsx.ca',
    timezone: {
      timezone: 'America/Toronto',
      abbr: 'EST',
      abbr_dst: 'EDT',
    },
    currency: {
      code: 'CAD',
      symbol: 'CA$',
      name: 'Canadian Dollar',
    },
  },
  {
    name: 'Santiago Stock Exchange',
    acronym: 'BVS',
    mic: 'XSGO',
    country: 'Chile',
    country_code: 'CL',
    city: 'Santiago',
    website: 'www.bolsadesantiago.com',
    timezone: {
      timezone: 'America/Santiago',
      abbr: '-03',
      abbr_dst: '-04',
    },
    currency: {
      code: 'CLP',
      symbol: 'CL$',
      name: 'Chilean Peso',
    },
  },
  {
    name: 'Shanghai Stock Exchange',
    acronym: 'SSE',
    mic: 'XSHG',
    country: 'China',
    country_code: 'CN',
    city: 'Shanghai',
    website: 'www.sse.com.cn',
    timezone: {
      timezone: 'Asia/Shanghai',
      abbr: 'CST',
      abbr_dst: 'CST',
    },
    currency: {
      code: 'CNY',
      symbol: 'CN¥',
      name: 'Chinese Yuan',
    },
  },
  {
    name: 'Shenzhen Stock Exchange',
    acronym: 'SZSE',
    mic: 'XSHE',
    country: 'China',
    country_code: 'CN',
    city: 'Shenzhen',
    website: 'www.szse.cn',
    timezone: {
      timezone: 'Asia/Shanghai',
      abbr: 'CST',
      abbr_dst: 'CST',
    },
    currency: {
      code: 'CNY',
      symbol: 'CN¥',
      name: 'Chinese Yuan',
    },
  },
  {
    name: 'Bolsa de Valores de Colombia',
    acronym: 'BVC',
    mic: 'XBOG',
    country: 'Colombia',
    country_code: 'CO',
    city: 'Bogota',
    website: 'www.bvc.com.co',
    timezone: {
      timezone: 'America/New_York',
      abbr: 'EST',
      abbr_dst: 'EDT',
    },
    currency: {
      code: 'COP',
      symbol: 'CO$',
      name: 'Colombian Peso',
    },
  },
  {
    name: 'Copenhagen Stock Exchange',
    acronym: 'OMXC',
    mic: 'XCSE',
    country: 'Denmark',
    country_code: 'DK',
    city: 'Copenhagen',
    website: 'www.nasdaqomxnordic.com',
    timezone: {
      timezone: 'Europe/Copenhagen',
      abbr: 'CET',
      abbr_dst: 'CEST',
    },
    currency: {
      code: 'DKK',
      symbol: 'Dkr',
      name: 'Danish Krone',
    },
  },
  {
    name: 'Eqyptian Exchange',
    acronym: 'EGX',
    mic: 'XCAI',
    country: 'Egypt',
    country_code: 'EG',
    city: 'Cairo',
    website: 'www.egyptse.com',
    timezone: {
      timezone: 'Africa/Cairo',
      abbr: 'EET',
      abbr_dst: 'EET',
    },
    currency: {
      code: 'EGP',
      symbol: 'EGP',
      name: 'Egyptian Pound',
    },
  },
  {
    name: 'Tallinn Stock Exchange',
    acronym: 'OMXT',
    mic: 'XTAL',
    country: 'Estonia',
    country_code: 'EE',
    city: 'Tallinn',
    website: 'www.nasdaqbaltic.com',
    timezone: {
      timezone: 'Europe/Tallinn',
      abbr: 'EET',
      abbr_dst: 'EEST',
    },
    currency: {
      code: 'EUR',
      symbol: '€',
      name: 'Euro',
    },
  },
  {
    name: 'Helsinki Stock Exchange',
    acronym: 'OMXH',
    mic: 'XHEL',
    country: 'Finland',
    country_code: 'FI',
    city: 'Helsinki',
    website: 'www.nasdaqomxnordic.com',
    timezone: {
      timezone: 'Europe/Helsinki',
      abbr: 'EET',
      abbr_dst: 'EEST',
    },
    currency: {
      code: 'EUR',
      symbol: '€',
      name: 'Euro',
    },
  },
  {
    name: 'Euronext Paris',
    acronym: 'Euronext',
    mic: 'XPAR',
    country: 'France',
    country_code: 'FR',
    city: 'Paris',
    website: 'www.euronext.com',
    timezone: {
      timezone: 'Europe/Paris',
      abbr: 'CET',
      abbr_dst: 'CEST',
    },
    currency: {
      code: 'EUR',
      symbol: '€',
      name: 'Euro',
    },
  },
  {
    name: 'Deutsche Börse',
    acronym: 'FSX',
    mic: 'XFRA',
    country: 'Germany',
    country_code: 'DE',
    city: 'Frankfurt',
    website: 'www.deutsche-boerse.com',
    timezone: {
      timezone: 'Europe/Berlin',
      abbr: 'CET',
      abbr_dst: 'CEST',
    },
    currency: {
      code: 'EUR',
      symbol: '€',
      name: 'Euro',
    },
  },
  {
    name: 'Börse Stuttgart',
    acronym: 'XSTU',
    mic: 'XSTU',
    country: 'Germany',
    country_code: 'DE',
    city: 'Stuttgart',
    website: 'www.boerse-stuttgart.de',
    timezone: {
      timezone: 'Europe/Berlin',
      abbr: 'CET',
      abbr_dst: 'CEST',
    },
    currency: {
      code: 'EUR',
      symbol: '€',
      name: 'Euro',
    },
  },
  {
    name: 'Deutsche Börse Xetra',
    acronym: 'XETR',
    mic: 'XETRA',
    country: 'Germany',
    country_code: 'DE',
    city: 'Frankfurt',
    website: '',
    timezone: {
      timezone: 'Europe/Berlin',
      abbr: 'CET',
      abbr_dst: 'CEST',
    },
    currency: {
      code: 'EUR',
      symbol: '€',
      name: 'Euro',
    },
  },
  {
    name: 'Hong Kong Stock Exchange',
    acronym: 'HKEX',
    mic: 'XHKG',
    country: 'Hong Kong',
    country_code: 'HK',
    city: 'Hong Kong',
    website: 'www.hkex.com.hk',
    timezone: {
      timezone: 'Asia/Hong_Kong',
      abbr: 'HKT',
      abbr_dst: 'HKT',
    },
    currency: {
      code: 'HKD',
      symbol: 'HK$',
      name: 'Hong Kong Dollar',
    },
  },
  {
    name: 'Nasdaq Island',
    acronym: 'XICE',
    mic: 'XICE',
    country: 'Iceland',
    country_code: 'IS',
    city: 'Reykjavík',
    website: 'www.nasdaqomxnordic.com',
    timezone: {
      timezone: 'Atlantic/Reykjavik',
      abbr: 'GMT',
      abbr_dst: 'GMT',
    },
    currency: {
      code: 'ISK',
      symbol: 'Ikr',
      name: 'Icelandic Króna',
    },
  },
  {
    name: 'Bombay Stock Exchange',
    acronym: 'MSE',
    mic: 'XBOM',
    country: 'India',
    country_code: 'IN',
    city: 'Mumbai',
    website: 'www.bseindia.com',
    timezone: {
      timezone: 'Asia/Kolkata',
      abbr: 'IST',
      abbr_dst: 'IST',
    },
    currency: {
      code: 'INR',
      symbol: 'Rs',
      name: 'Indian Rupee',
    },
  },
  {
    name: 'National Stock Exchange India',
    acronym: 'NSE',
    mic: 'XNSE',
    country: 'India',
    country_code: 'IN',
    city: 'Mumbai',
    website: 'www.nseindia.com',
    timezone: {
      timezone: 'Asia/Kolkata',
      abbr: 'IST',
      abbr_dst: 'IST',
    },
    currency: {
      code: 'INR',
      symbol: 'Rs',
      name: 'Indian Rupee',
    },
  },
  {
    name: 'Jakarta Stock Exchange',
    acronym: 'IDX',
    mic: 'XIDX',
    country: 'Indonesia',
    country_code: 'ID',
    city: 'Jakarta',
    website: 'www.idx.co.id',
    timezone: {
      timezone: 'Asia/Jakarta',
      abbr: 'WIB',
      abbr_dst: 'WIB',
    },
    currency: {
      code: 'IDR',
      symbol: 'Rp',
      name: 'Indonesian Rupiah',
    },
  },
  {
    name: 'Tel Aviv Stock Exchange',
    acronym: 'TASE',
    mic: 'XTAE',
    country: 'Israel',
    country_code: 'IL',
    city: 'Tel Aviv',
    website: 'www.tase.co.il',
    timezone: {
      timezone: 'Asia/Jerusalem',
      abbr: 'IST',
      abbr_dst: 'IDT',
    },
    currency: {
      code: 'ILS',
      symbol: '₪',
      name: 'Israeli New Sheqel',
    },
  },
  {
    name: 'Borsa Italiana',
    acronym: 'MIL',
    mic: 'XMIL',
    country: 'Italy',
    country_code: 'IT',
    city: 'Milano',
    website: 'www.borsaitaliana.it',
    timezone: {
      timezone: 'Europe/Rome',
      abbr: 'CET',
      abbr_dst: 'CEST',
    },
    currency: {
      code: 'EUR',
      symbol: '€',
      name: 'Euro',
    },
  },
  {
    name: 'Nagoya Stock Exchange',
    acronym: 'NSE',
    mic: 'XNGO',
    country: 'Japan',
    country_code: 'JP',
    city: 'Nagoya',
    website: 'www.nse.or.jp',
    timezone: {
      timezone: 'Asia/Tokyo',
      abbr: 'JST',
      abbr_dst: 'JST',
    },
    currency: {
      code: 'JPY',
      symbol: '¥',
      name: 'Japanese Yen',
    },
  },
  {
    name: 'Fukuoka Stock Exchange',
    acronym: 'XFKA',
    mic: 'XFKA',
    country: 'Japan',
    country_code: 'JP',
    city: 'Fukuoka',
    website: 'www.fse.or.jp',
    timezone: {
      timezone: 'Asia/Tokyo',
      abbr: 'JST',
      abbr_dst: 'JST',
    },
    currency: {
      code: 'JPY',
      symbol: '¥',
      name: 'Japanese Yen',
    },
  },
  {
    name: 'Sapporo Stock Exchange',
    acronym: 'XSAP',
    mic: 'XSAP',
    country: 'Japan',
    country_code: 'JP',
    city: 'Sapporo',
    website: 'www.sse.or.jp',
    timezone: {
      timezone: 'Asia/Tokyo',
      abbr: 'JST',
      abbr_dst: 'JST',
    },
    currency: {
      code: 'JPY',
      symbol: '¥',
      name: 'Japanese Yen',
    },
  },
  {
    name: 'Nasdaq Riga',
    acronym: 'OMXR',
    mic: 'XRIS',
    country: 'Latvia',
    country_code: 'LV',
    city: 'Riga',
    website: 'www.nasdaqbaltic.com',
    timezone: {
      timezone: 'Europe/Riga',
      abbr: 'EET',
      abbr_dst: 'EEST',
    },
    currency: {
      code: 'EUR',
      symbol: '€',
      name: 'Euro',
    },
  },
  {
    name: 'Nasdaq Vilnius',
    acronym: 'OMXV',
    mic: 'XLIT',
    country: 'Lithuania',
    country_code: 'LT',
    city: 'Vilnius',
    website: 'www.nasdaqbaltic.com',
    timezone: {
      timezone: 'Europe/Vilnius',
      abbr: 'EET',
      abbr_dst: 'EEST',
    },
    currency: {
      code: 'EUR',
      symbol: '€',
      name: 'Euro',
    },
  },
  {
    name: 'Malaysia Stock Exchange',
    acronym: 'MYX',
    mic: 'XKLS',
    country: 'Malaysia',
    country_code: 'MY',
    city: 'Kuala Lumpur',
    website: 'www.bursamalaysia.com',
    timezone: {
      timezone: 'Asia/Kuala_Lumpur',
      abbr: '+08',
      abbr_dst: '+08',
    },
    currency: {
      code: 'MYR',
      symbol: 'RM',
      name: 'Malaysian Ringgit',
    },
  },
  {
    name: 'Mexican Stock Exchange',
    acronym: 'BMV',
    mic: 'XMEX',
    country: 'Mexico',
    country_code: 'MX',
    city: 'Mexico City',
    website: 'www.bmv.com.mx',
    timezone: {
      timezone: 'America/Mexico_City',
      abbr: 'CST',
      abbr_dst: 'CDT',
    },
    currency: {
      code: 'MXN',
      symbol: 'MX$',
      name: 'Mexican Peso',
    },
  },
  {
    name: 'Euronext Amsterdam',
    acronym: 'Euronext',
    mic: 'XAMS',
    country: 'Netherlands',
    country_code: 'NL',
    city: 'Amsterdam',
    website: 'www.euronext.com',
    timezone: {
      timezone: 'Europe/Amsterdam',
      abbr: 'CET',
      abbr_dst: 'CEST',
    },
    currency: {
      code: 'EUR',
      symbol: '€',
      name: 'Euro',
    },
  },
  {
    name: 'New Zealand Stock Exchange',
    acronym: 'NZX',
    mic: 'XNZE',
    country: 'New Zealand',
    country_code: 'NZ',
    city: 'Wellington',
    website: 'www.nzx.com',
    timezone: {
      timezone: 'Pacific/Auckland',
      abbr: 'NZDT',
      abbr_dst: 'NZST',
    },
    currency: {
      code: 'NZD',
      symbol: 'NZ$',
      name: 'New Zealand Dollar',
    },
  },
  {
    name: 'Nigerian Stock Exchange',
    acronym: 'NSE',
    mic: 'XNSA',
    country: 'Nigeria',
    country_code: 'NG',
    city: 'Lagos',
    website: 'www.nse.com.ng',
    timezone: {
      timezone: 'Africa/Lagos',
      abbr: 'WAT',
      abbr_dst: 'WAT',
    },
    currency: {
      code: 'NGN',
      symbol: '₦',
      name: 'Nigerian Naira',
    },
  },
  {
    name: 'Oslo Stock Exchange',
    acronym: 'OSE',
    mic: 'XOSL',
    country: 'Norway',
    country_code: 'NO',
    city: 'Oslo',
    website: 'www.oslobors.no',
    timezone: {
      timezone: 'Europe/Oslo',
      abbr: 'CET',
      abbr_dst: 'CEST',
    },
    currency: {
      code: 'NOK',
      symbol: 'Nkr',
      name: 'Norwegian Krone',
    },
  },
  {
    name: 'Bolsa de Valores de Lima',
    acronym: 'BVL',
    mic: 'XLIM',
    country: 'Peru',
    country_code: 'PE',
    city: 'Lima',
    website: 'www.bvl.com.pe',
    timezone: {
      timezone: 'America/New_York',
      abbr: 'EST',
      abbr_dst: 'EDT',
    },
    currency: {
      code: 'PEN',
      symbol: 'S/.',
      name: 'Peruvian Nuevo Sol',
    },
  },
  {
    name: 'Warsaw Stock Exchange',
    acronym: 'GPW',
    mic: 'XWAR',
    country: 'Poland',
    country_code: 'PL',
    city: 'Warsaw',
    website: 'www.gpw.pl',
    timezone: {
      timezone: 'Europe/Warsaw',
      abbr: 'CET',
      abbr_dst: 'CEST',
    },
    currency: {
      code: 'EUR',
      symbol: '€',
      name: 'Euro',
    },
  },
  {
    name: 'Euronext Lisbon',
    acronym: 'Euronext',
    mic: 'XLIS',
    country: 'Portugal',
    country_code: 'PT',
    city: 'Lisboa',
    website: 'www.euronext.com',
    timezone: {
      timezone: 'Europe/Lisbon',
      abbr: 'WET',
      abbr_dst: 'WEST',
    },
    currency: {
      code: 'EUR',
      symbol: '€',
      name: 'Euro',
    },
  },
  {
    name: 'Qatar Stock Exchange',
    acronym: 'QE',
    mic: 'DSMD',
    country: 'Qatar',
    country_code: 'QA',
    city: 'Doha',
    website: 'www.qatarexchange.qa',
    timezone: {
      timezone: 'Asia/Qatar',
      abbr: '+03',
      abbr_dst: '+03',
    },
    currency: {
      code: 'QAR',
      symbol: 'QR',
      name: 'Qatari Rial',
    },
  },
  {
    name: 'Moscow Stock Exchange',
    acronym: 'MOEX',
    mic: 'MISX',
    country: 'Russia',
    country_code: 'RU',
    city: 'Moscow',
    website: 'www.moex.com',
    timezone: {
      timezone: 'Europe/Moscow',
      abbr: 'MSK',
      abbr_dst: 'MSK',
    },
    currency: {
      code: 'RUB',
      symbol: 'RUB',
      name: 'Russian Ruble',
    },
  },
  {
    name: 'Saudi Stock Exchange',
    acronym: 'TADAWUL',
    mic: 'XSAU',
    country: 'Saudi Arabia',
    country_code: 'SA',
    city: 'Riyadh',
    website: 'www.tadawul.com.sa',
    timezone: {
      timezone: 'Asia/Riyadh',
      abbr: '+03',
      abbr_dst: '+03',
    },
    currency: {
      code: 'SAR',
      symbol: 'SR',
      name: 'Saudi Riyal',
    },
  },
  {
    name: 'Belgrade Stock Exchange',
    acronym: 'BELEX',
    mic: 'XBEL',
    country: 'Serbia',
    country_code: 'RS',
    city: 'Belgrade',
    website: 'www.belex.rs',
    timezone: {
      timezone: 'Europe/Belgrade',
      abbr: 'CET',
      abbr_dst: 'CEST',
    },
    currency: {
      code: 'EUR',
      symbol: '€',
      name: 'Euro',
    },
  },
  {
    name: 'Singapore Stock Exchange',
    acronym: 'SGX',
    mic: 'XSES',
    country: 'Singapore',
    country_code: 'SG',
    city: 'Singapore',
    website: 'www.sgx.com',
    timezone: {
      timezone: 'Asia/Singapore',
      abbr: '+08',
      abbr_dst: '+08',
    },
    currency: {
      code: 'SGD',
      symbol: 'S$',
      name: 'Singapore Dollar',
    },
  },
  {
    name: 'Johannesburg Stock Exchange',
    acronym: 'JSE',
    mic: 'XJSE',
    country: 'South Africa',
    country_code: 'ZA',
    city: 'Johannesburg',
    website: 'www.jse.co.za',
    timezone: {
      timezone: 'Africa/Johannesburg',
      abbr: 'SAST',
      abbr_dst: 'SAST',
    },
    currency: {
      code: 'ZAR',
      symbol: 'R',
      name: 'South African Rand',
    },
  },
  {
    name: 'Korean Stock Exchange',
    acronym: 'KRX',
    mic: 'XKRX',
    country: 'South Korea',
    country_code: 'KR',
    city: 'Seoul',
    website: 'http://eng.krx.co.kr',
    timezone: {
      timezone: 'Asia/Seoul',
      abbr: 'KST',
      abbr_dst: 'KST',
    },
    currency: {
      code: 'KRW',
      symbol: '₩',
      name: 'South Korean Won',
    },
  },
  {
    name: 'Bolsas y Mercados Españoles',
    acronym: 'BME',
    mic: 'BMEX',
    country: 'Spain',
    country_code: 'ES',
    city: 'Madrid',
    website: 'www.bolsasymercados.es',
    timezone: {
      timezone: 'Europe/Madrid',
      abbr: 'CET',
      abbr_dst: 'CEST',
    },
    currency: {
      code: 'EUR',
      symbol: '€',
      name: 'Euro',
    },
  },
  {
    name: 'Stockholm Stock Exchange',
    acronym: 'OMX',
    mic: 'XSTO',
    country: 'Sweden',
    country_code: 'SE',
    city: 'Stockholm',
    website: 'www.nasdaqomxnordic.com',
    timezone: {
      timezone: 'Europe/Stockholm',
      abbr: 'CET',
      abbr_dst: 'CEST',
    },
    currency: {
      code: 'EUR',
      symbol: '€',
      name: 'Euro',
    },
  },
  {
    name: 'SIX Swiss Exchange',
    acronym: 'SIX',
    mic: 'XSWX',
    country: 'Switzerland',
    country_code: 'CH',
    city: 'Zurich',
    website: 'www.six-swiss-exchange.com',
    timezone: {
      timezone: 'Europe/Zurich',
      abbr: 'CET',
      abbr_dst: 'CEST',
    },
    currency: {
      code: 'CHF',
      symbol: 'CHF',
      name: 'Swiss Franc',
    },
  },
  {
    name: 'Taiwan Stock Exchange',
    acronym: 'TWSE',
    mic: 'XTAI',
    country: 'Taiwan',
    country_code: 'TW',
    city: 'Taipei',
    website: 'www.twse.com.tw/en/',
    timezone: {
      timezone: 'Asia/Taipei',
      abbr: 'CST',
      abbr_dst: 'CST',
    },
    currency: {
      code: 'TWD',
      symbol: 'NT$',
      name: 'New Taiwan Dollar',
    },
  },
  {
    name: 'Stock Exchange of Thailand',
    acronym: 'SET',
    mic: 'XBKK',
    country: 'Thailand',
    country_code: 'TH',
    city: 'Bangkok',
    website: 'www.set.or.th',
    timezone: {
      timezone: 'Asia/Bangkok',
      abbr: '+07',
      abbr_dst: '+07',
    },
    currency: {
      code: 'THB',
      symbol: '฿',
      name: 'Thai Baht',
    },
  },
  {
    name: 'Istanbul Stock Exchange',
    acronym: 'BIST',
    mic: 'XIST',
    country: 'Turkey',
    country_code: 'TR',
    city: 'Istanbul',
    website: 'www.borsaistanbul.com',
    timezone: {
      timezone: 'Europe/Istanbul',
      abbr: '+03',
      abbr_dst: '+03',
    },
    currency: {
      code: 'TRY',
      symbol: 'TL',
      name: 'Turkish Lira',
    },
  },
  {
    name: 'Dubai Financial Market',
    acronym: 'DFM',
    mic: 'XDFM',
    country: 'United Arab Emirates',
    country_code: 'AE',
    city: 'Dubai',
    website: 'www.dfm.co.ae',
    timezone: {
      timezone: 'Asia/Dubai',
      abbr: '+04',
      abbr_dst: '+04',
    },
    currency: {
      code: 'AED',
      symbol: 'AED',
      name: 'United Arab Emirates Dirham',
    },
  },
  {
    name: 'London Stock Exchange',
    acronym: 'LSE',
    mic: 'XLON',
    country: 'United Kingdom',
    country_code: 'GB',
    city: 'London',
    website: 'www.londonstockexchange.com',
    timezone: {
      timezone: 'Europe/London',
      abbr: 'GMT',
      abbr_dst: 'BST',
    },
    currency: {
      code: 'GBP',
      symbol: '£',
      name: 'British Pound Sterling',
    },
  },
  {
    name: 'Ho Chi Minh Stock Exchange',
    acronym: 'HOSE',
    mic: 'XSTC',
    country: 'Vietnam',
    country_code: 'VN',
    city: 'Ho Chi Minh City',
    website: 'www.hsx.vn',
    timezone: {
      timezone: 'Asia/Ho_Chi_Minh',
      abbr: '+07',
      abbr_dst: '+07',
    },
    currency: {
      code: 'VND',
      symbol: '₫',
      name: 'Vietnamese Dong',
    },
  },
  {
    name: 'American Stock Exchange',
    acronym: 'AMEX',
    mic: 'XASE',
    country: 'USA',
    country_code: 'US',
    city: 'New York',
    website: 'www.nyse.com',
    timezone: {
      timezone: 'America/New_York',
      abbr: 'EST',
      abbr_dst: 'EDT',
    },
    currency: {
      code: 'USD',
      symbol: '$',
      name: 'US Dollar',
    },
  },
  {
    name: 'Cboe BZX U.S. Equities Exchang',
    acronym: 'BATS',
    mic: 'XCBO',
    country: 'USA',
    country_code: 'US',
    city: 'Chicago',
    website: 'markets.cboe.com',
    timezone: {
      timezone: 'America/Chicago',
      abbr: 'CDT',
      abbr_dst: 'CDT',
    },
    currency: {
      code: 'USD',
      symbol: '$',
      name: 'US Dollar',
    },
  },
  {
    name: 'US Mutual Funds',
    acronym: 'NMFQS',
    mic: 'NMFQS',
    country: 'USA',
    country_code: 'US',
    city: 'New York',
    website: '',
    timezone: {
      timezone: 'America/New_York',
      abbr: 'EST',
      abbr_dst: 'EDT',
    },
    currency: {
      code: 'USD',
      symbol: '$',
      name: 'US Dollar',
    },
  },
  {
    name: 'OTC Bulletin Board',
    acronym: 'OTCBB',
    mic: 'OOTC',
    country: 'USA',
    country_code: 'US',
    city: 'Washington',
    website: 'www.otcmarkets.com',
    timezone: {
      timezone: 'America/New_York',
      abbr: 'EST',
      abbr_dst: 'EDT',
    },
    currency: {
      code: 'USD',
      symbol: '$',
      name: 'US Dollar',
    },
  },
  {
    name: 'OTC Grey Market',
    acronym: 'OTCGREY',
    mic: 'PSGM',
    country: 'USA',
    country_code: 'US',
    city: 'New York',
    website: 'www.otcmarkets.com',
    timezone: {
      timezone: 'America/New_York',
      abbr: 'EST',
      abbr_dst: 'EDT',
    },
    currency: {
      code: 'USD',
      symbol: '$',
      name: 'US Dollar',
    },
  },
  {
    name: 'OTCQB Marketplace',
    acronym: 'OTCQB',
    mic: 'OTCB',
    country: 'USA',
    country_code: 'US',
    city: 'New York',
    website: 'www.otcmarkets.com',
    timezone: {
      timezone: 'America/New_York',
      abbr: 'EST',
      abbr_dst: 'EDT',
    },
    currency: {
      code: 'USD',
      symbol: '$',
      name: 'US Dollar',
    },
  },
  {
    name: 'OTCQX Marketplace',
    acronym: 'OTCQX',
    mic: 'OTCQ',
    country: 'USA',
    country_code: 'US',
    city: 'New York',
    website: 'www.otcmarkets.com',
    timezone: {
      timezone: 'America/New_York',
      abbr: 'EST',
      abbr_dst: 'EDT',
    },
    currency: {
      code: 'USD',
      symbol: '$',
      name: 'US Dollar',
    },
  },
  {
    name: 'OTC PINK current',
    acronym: 'PINK',
    mic: 'PINC',
    country: 'USA',
    country_code: 'US',
    city: 'New York',
    website: 'www.otcmarkets.com',
    timezone: {
      timezone: 'America/New_York',
      abbr: 'EST',
      abbr_dst: 'EDT',
    },
    currency: {
      code: 'USD',
      symbol: '$',
      name: 'US Dollar',
    },
  },
  {
    name: 'Investors Exchange',
    acronym: 'IEX',
    mic: 'IEXG',
    country: 'USA',
    country_code: 'US',
    city: 'New York',
    website: 'www.iextrading.com',
    timezone: {
      timezone: 'America/New_York',
      abbr: 'EST',
      abbr_dst: 'EDT',
    },
    currency: {
      code: 'USD',
      symbol: '$',
      name: 'US Dollar',
    },
  },
  {
    name: 'INDEX',
    acronym: null,
    mic: 'INDX',
    country: null,
    country_code: 'INDX',
    city: null,
    website: '',
    timezone: null,
    currency: null,
  },
];
