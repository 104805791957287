const authPath = '/';
const privacyPath = '/privacy-policy';
const tosPath = '/terms-of-service';

const stockListViews = [
  {
    name: 'Performance',
    url: '/performance',
  },
  {
    name: 'Holdings',
    url: '/holdings',
  },
];

const needsAuth = path => [authPath, privacyPath, tosPath].indexOf(path) === -1;

export { stockListViews, authPath, privacyPath, tosPath, needsAuth };
