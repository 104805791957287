import { ADD_USER, REMOVE_USER } from '../actions/types';

const initialState = { status: 'pending' };

const user = (state = initialState, action) => {
  switch (action.type) {
    case ADD_USER:
      return {
        ...state,
        ...action.user,
      };

    case REMOVE_USER:
      return { status: action.status };

    default:
      return state;
  }
};

export default user;
