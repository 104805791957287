import safelyGetProperty from './safelyGetProperty';
import { parseDateAsInteger } from 'helpers/dates';

const calculateHoldings = sellBucket => (sortedHoldings, nextHolding) => {
  const { symbol, currency } = nextHolding;

  const sellBucketHolding = sellBucket[symbol];
  const sellBucketQuantity = safelyGetProperty(
    sellBucketHolding,
    'totalQuantity'
  );

  const currentHolding = sortedHoldings[symbol];
  const currentHoldingQuantity = safelyGetProperty(currentHolding, 'quantity');
  const currentHoldingTotalCost = safelyGetProperty(
    currentHolding,
    'totalCost'
  );

  const nextHoldingQuantity = safelyGetProperty(nextHolding, 'quantity');
  const nextHoldingLeftOverQuantity = nextHoldingQuantity - sellBucketQuantity;
  const nextHoldingAllSold = nextHoldingLeftOverQuantity <= 0;
  const nextHoldingCost = nextHoldingAllSold
    ? 0
    : nextHolding.purchasePrice * nextHoldingLeftOverQuantity;

  if (sellBucketHolding && sellBucketHolding.totalQuantity > 0) {
    sellBucketHolding.totalQuantity -= nextHoldingQuantity;

    if (sellBucketHolding.totalQuantity < 0)
      sellBucketHolding.totalQuantity = 0;
  }

  return {
    ...sortedHoldings,
    [symbol]: {
      symbol,
      currency,
      name: nextHolding.name,
      totalCost: currentHoldingTotalCost + nextHoldingCost,
      quantity: nextHoldingAllSold
        ? // entire holding is sold, keep holding quantity at its current value
          currentHoldingQuantity
        : // the entire holding wasnt sold (remains either in part or whole of
          // original buy), apply the left over to the holding quantity
          currentHoldingQuantity + nextHoldingLeftOverQuantity,
    },
  };
};

const filterTransactions = transactions => {
  let sellBucket = {};

  const buyTransactions = transactions.filter(transaction => {
    const { symbol, quantity } = transaction;
    const isSellTransaction = quantity < 0;

    if (isSellTransaction) {
      const sellBucketHolding = sellBucket[symbol];
      const sellBucketHoldingQuantity = safelyGetProperty(
        sellBucketHolding,
        'totalQuantity'
      );

      sellBucket = {
        ...sellBucket,
        [symbol]: {
          symbol,
          totalQuantity: sellBucketHoldingQuantity + Math.abs(quantity),
        },
      };
    }

    return isSellTransaction === false;
  });

  return { buyTransactions, sellBucket };
};

const sortTransactionsByDate = (a, b) =>
  parseDateAsInteger(b.purchaseDate) - parseDateAsInteger(a.purchaseDate);

const sortTransactionsBySymbol = (a, b) =>
  a.symbol.toUpperCase() < b.symbol.toUpperCase() ? -1 : 1;

export const getHoldings = transactions => {
  const { buyTransactions, sellBucket } = filterTransactions(transactions);

  return buyTransactions
    .sort(sortTransactionsByDate)
    .sort(sortTransactionsBySymbol)
    .reduce(calculateHoldings(sellBucket), {});
};
