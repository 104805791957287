import './StockSearch.css';

import React, { Component } from 'react';
import Autosuggest from 'react-autosuggest';
import debounce from 'lodash/debounce';

import { symbolLookup } from 'helpers/apiPaths';
import { addNotification } from 'actions';

export default class StockSearch extends Component {
  state = {
    initialLoad: true,
    loading: false,
    results: [],
    value: '',
    name: '',
  };

  getResults = ({ value }) => {
    if (value === this.props.symbol) return;

    this.props.update('');

    if (value === '' || value === undefined) return;

    this.setState({ loading: true, initialLoad: false });

    fetch(`${symbolLookup}/${value}`)
      .then(response => {
        if (response.status !== 200) {
          return addNotification({
            message: `Bad Stock lookup. Status: ${response.status}`,
            isError: true,
          });
        }
        return response.json();
      })
      .then(({ data }) => {
        this.setState({ results: data.filter(e => e.has_eod), loading: false });
        this.checkPerfectMatch(data);
      })
      .catch(err => console.error('Fetch Error in stock symbol lookup', err));
  };

  throttledResults = debounce(this.getResults, 500, {
    leading: false,
    maxWait: 2000,
  });

  handleChange = (event, { newValue }) => this.setState({ value: newValue });

  // check to see if first result matches the input exactly
  checkPerfectMatch = () => {
    const formattedValue = this.state.value.toUpperCase();
    const onlyResultMatchesValue =
      this.state.results.length > 0 &&
      this.state.results[0].symbol === formattedValue;

    if (onlyResultMatchesValue) {
      this.setState({ value: formattedValue });
      this.props.update(formattedValue);
    }
  };

  renderSuggestion = ({ symbol, name, typeDisp, stock_exchange }) => (
    <div>
      <b>{symbol}</b> &nbsp;{name}
      <span className="Modal__suggestion-right">{stock_exchange.acronym}</span>
    </div>
  );

  handleInputBlur = ({ onBlur }) => {
    this.checkPerfectMatch();
    onBlur();
  };

  renderInputComponent = inputProps => (
    <input {...inputProps} onBlur={() => this.handleInputBlur(inputProps)} />
  );

  render() {
    const { value, loading, results } = this.state;
    const inputProps = {
      placeholder: 'e.g. AAPL, VTI, MSFT',
      value: value,
      onChange: this.handleChange,
      className: 'Modal__input',
    };

    const loadingClass = loading ? 'Modal__search--loading' : '';
    const invalidClass =
      !this.props.symbol && !this.state.initialLoad
        ? 'Modal__search--invalid'
        : '';

    return (
      <div className={`Modal__search ${loadingClass} ${invalidClass}`}>
        <Autosuggest
          suggestions={results}
          onSuggestionsFetchRequested={this.throttledResults}
          onSuggestionsClearRequested={() => this.setState({ results: [] })}
          getSuggestionValue={suggestion => suggestion.symbol}
          renderSuggestion={this.renderSuggestion}
          shouldRenderSuggestions={value => value.trim().length > 0}
          onSuggestionSelected={(event, { suggestion }) =>
            this.props.update(suggestion.symbol, suggestion.name)
          }
          focusInputOnSuggestionClick={false}
          inputProps={inputProps}
          renderInputComponent={this.renderInputComponent}
        />
      </div>
    );
  }
}
