import React from 'react';

import reduxStore from '../../helpers/reduxStore';
import LoadingPlaceholder from '../../components/LoadingPlaceholder';
import ChangeStats from './ChangeStats';

export const HOLDING = 'HOLDING';
export const TRANSACTION = 'TRANSACTION';

const makePrettyName = (stock, stock_name) => {
  const selectedCurrency = reduxStore.getState().settings.currency;
  const currencyPrepend =
    selectedCurrency !== stock.currency ? stock.currency : null;

  return `${stock_name || stock.symbol}${
    currencyPrepend ? ` (${currencyPrepend.toUpperCase()})` : ''
  }`;
};

const currencySymbols = {
  CAD: String.fromCharCode(0x0024),
  CHF: 'CHF',
  EUR: String.fromCharCode(0x20ac),
  GBP: String.fromCharCode(0x00a3),
  JPY: String.fromCharCode(0x00a5),
  USD: String.fromCharCode(0x0024),
};

export const formatNumber = (number, decimals) =>
  number === undefined
    ? ''
    : number.toFixed(decimals ? 2 : 0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatCurrency = ({ quantity, currency, decimals }) => {
  if (!currency) return `No data`;
  const sign = quantity < 0 ? '-' : '';
  const currencySymbol = currencySymbols[currency.toUpperCase()];
  const defaultSymbol = String.fromCharCode(0x0024);
  const formattedNumber = formatNumber(Math.abs(quantity), decimals);

  return `${sign}${currencySymbol || defaultSymbol}${formattedNumber}`;
};

export const calculateChange = ({ quantity, avgPurchasePrice }) => ({
  stock,
}) => {
  if (!stock || !stock.price) {
    return <LoadingPlaceholder />;
  }

  if (quantity === 0) {
    return 'N/A';
  }

  const changeDelta = (stock.price - avgPurchasePrice) * quantity;

  return (
    <ChangeStats
      changeDelta={changeDelta}
      bookValue={avgPurchasePrice * quantity}
      currency={stock.currency}
    />
  );
};

export const getCellValuesForHolding = ({
  purchasePrice,
  quantity,
  symbol,
  totalCost,
  name,
}) => ({
  'Avg Price': ({ stock }) =>
    stock &&
    formatCurrency({
      quantity: quantity > 0 ? totalCost / quantity : 0,
      currency: stock.currency,
      decimals: true,
    }),
  'Book Value': ({ stock }) =>
    stock && formatCurrency({ quantity: totalCost, currency: stock.currency }),
  'Market Value': ({ stock }) =>
    stock &&
    formatCurrency({
      quantity: stock.price * quantity,
      currency: stock.currency,
    }),
  Change: calculateChange({ quantity, avgPurchasePrice: totalCost / quantity }),
  Name: ({ stock, name }) => stock && makePrettyName(stock, name),
  Quantity: () => quantity,
  Symbol: () => symbol,
});

export const getCellValuesForTransaction = ({
  purchaseDate,
  purchasePrice,
  quantity,
}) => ({
  ...getCellValuesForHolding({ purchaseDate, purchasePrice, quantity }),
  'Avg Price': ({ stock }) =>
    stock &&
    formatCurrency({
      quantity: purchasePrice,
      currency: stock.currency,
      decimals: true,
    }),
  'Book Value': ({ stock }) =>
    stock &&
    formatCurrency({
      quantity: purchasePrice * quantity,
      currency: stock.currency,
    }),
  Name: () => `${purchaseDate} \u2014 ${quantity < 0 ? 'Sell' : 'Buy'}`,
  Quantity: () => Math.abs(quantity),
  Symbol: () => ' ',
});

export const calculateOpenHistoryViews = ({ openHistoryViews, symbol }) => {
  let newOpenHistoryViews = [...openHistoryViews];
  const symbolIndex = newOpenHistoryViews.indexOf(symbol);

  if (symbolIndex === -1) {
    newOpenHistoryViews.push(symbol);
  } else {
    newOpenHistoryViews.splice(symbolIndex, 1);
  }

  return newOpenHistoryViews;
};
