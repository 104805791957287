import { GET_STOCKS } from '../actions/types';

const initialState = {};

const stocks = (state = initialState, action) => {
  switch (action.type) {
    case GET_STOCKS:
      return { ...state, ...action.stocks };

    default:
      return state;
  }
};

export default stocks;
