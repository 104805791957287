import 'react-day-picker/lib/style.css';
import './Modal.css';

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate } from 'react-day-picker/moment';
import { CSSTransition } from 'react-transition-group';

import { addTransaction, getStocks } from '../../actions';
import Button from '../Button';
import StockDropdown from './StockDropdown';
import StockSearch from './StockSearch';
import {
  MODAL_SELL_TRANSACTION,
  MODAL_ADD_TRANSACTION,
} from '../../helpers/constants';

const formatDateValue = date => formatDate(date, 'YYYY-MM-DD');

const validateInput = ({ symbol, price, quantity, date }) =>
  symbol && price && quantity && date ? false : true;

const processQuantity = (quantity, type) =>
  Number(quantity) * (type === MODAL_SELL_TRANSACTION ? -1 : 1);

const mapStateToProps = state => ({ user: state.user });

const mapDispatchToProps = dispatch => ({
  addTransaction: transaction =>
    dispatch(addTransaction(transaction)).then(() => {
      dispatch(getStocks([transaction.symbol]));
    }),
});

class Modal extends Component {
  state = {
    symbol: '',
    price: '',
    quantity: '',
    name: '',
    date: formatDateValue(new Date()),
  };

  handleChange = (value, event) =>
    this.setState({ [value]: event.target.value });

  handleDateChange = (selectedDay, modifiers, dayPickerInput) => {
    this.setState({
      date: formatDateValue(selectedDay),
    });
  };

  addTransaction = () =>
    this.props.addTransaction({
      symbol: this.state.symbol,
      purchasePrice: Number(this.state.price),
      quantity: processQuantity(this.state.quantity, this.props.type),
      purchaseDate: this.state.date,
      userId: this.props.user.uid,
      name: this.state.name,
    });

  render() {
    const { type } = this.props;
    const dayPickerProps = {
      className: 'Modal__date-container',
      disabledDays: { after: new Date() },
    };

    return (
      <CSSTransition in={true} key={type} classNames="Modal-fade" timeout={400}>
        <div>
          <Link to="/holdings" className="Modal__overlay" />

          <div className="Modal">
            <Link to="/holdings" className="Modal__close">
              Cancel
            </Link>
            <h2 className="Modal__title">{type} a holding</h2>

            <div className="Modal__row">
              <label className="Modal__input-label">
                {type === MODAL_ADD_TRANSACTION && 'Stock symbol'}

                {type === MODAL_ADD_TRANSACTION ? (
                  <StockSearch
                    update={(symbol, name) => this.setState({ symbol, name })}
                    symbol={this.state.symbol}
                  />
                ) : (
                  <StockDropdown onChange={this.handleChange} />
                )}
              </label>
            </div>

            <div className="Modal__row">
              <label className="Modal__input-label">
                Date {type === MODAL_ADD_TRANSACTION ? ' purchased' : ' sold'}
                <DayPickerInput
                  placeholder={`${formatDateValue(new Date())}`}
                  onDayChange={this.handleDateChange}
                  dayPickerProps={dayPickerProps}
                  inputProps={{ className: 'Modal__input Modal__date' }}
                />
              </label>

              <label className="Modal__input-label">
                Number of shares
                <input
                  id="Modal__input-quantity"
                  className="Modal__input"
                  pattern="[0-9\.]*"
                  value={this.state.quantity}
                  onChange={event => this.handleChange('quantity', event)}
                />
              </label>

              <label className="Modal__input-label">
                Share {type === MODAL_SELL_TRANSACTION ? 'sell' : ''} price
                <input
                  className="Modal__input"
                  pattern="[0-9\.]*"
                  value={this.state.price}
                  onChange={event => this.handleChange('price', event)}
                />
              </label>
            </div>

            <Button
              value={type}
              onClick={this.addTransaction}
              to="/holdings"
              disabled={validateInput(this.state)}
              className="Button--full-width Button--xl"
            />
          </div>
        </div>
      </CSSTransition>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
