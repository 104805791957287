import React from 'react';

import Header from '../components/Header';
import StockList from '../components/StockList';
import { PERFORMANCE_VIEW } from '../helpers/constants';
import Footer from 'components/Footer';

const columnTitles = ['Symbol', 'Name', 'Book Value', 'Market Value', 'Change'];

const Performance = () => (
  <div className="App--signedIn">
    <Header />
    <StockList columnTitles={columnTitles} view={PERFORMANCE_VIEW} />
    <Footer inApp />
  </div>
);

export default Performance;
