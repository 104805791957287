import './Button.css';

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const getSecondaryClass = secondary => (secondary ? 'Button-secondary' : '');

const Button = ({ className, value, secondary, to, onClick, disabled }) => {
  const ButtonComponent = to ? Link : 'button';
  return (
    <ButtonComponent
      to={to}
      onClick={onClick}
      disabled={disabled}
      className={`Button ${getSecondaryClass(secondary)} ${className}`}
    >
      {value}
    </ButtonComponent>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  secondary: PropTypes.bool,
  to: PropTypes.string,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  secondary: false,
};

export default Button;
