const gid = 'UA-6380101-18';
const is_production = process.env.NODE_ENV === 'production';

export const trackEvent = eventTitle =>
  is_production && window.gtag('event', eventTitle);

export const trackPage = page_path =>
  is_production &&
  window.gtag('config', gid, { page_path: page_path.pathname });

export const setUser = user_id =>
  is_production && window.gtag('config', gid, { user_id: user_id });
