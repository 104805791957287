import './Home.css';

import React from 'react';

import { authenticateUserWithGoogle } from 'helpers/firebase';
import { trackEvent } from 'helpers/analytics';
import logo from 'images/stockwallet-logo-with-name.svg';
import screenshot from './screenshot.png';
import screenshot2x from './screenshot@2x.png';
import Button from 'components/Button';
import Footer from 'components/Footer';

const Checkmark = ({ colour }) => (
  <svg
    className="Home__checkmark"
    width="30"
    height="20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.969 3.35721L8.10909 19.4408L0.536438 11.3098L3.46359 8.58367L8.09737 13.5591L20.031 0.642761L22.969 3.35721Z"
      fill={colour}
    />
  </svg>
);

const Home = () => {
  return (
    <div className="Home">
      <section className="Home__hero">
        <header className="Home__header">
          <img className="Home__logo" src={logo} alt="Stockwallet" />
          <Button
            className="Button--xl Home__getstarted"
            value="Get Started"
            onClick={() => {
              trackEvent('Clicked login/signup');
              authenticateUserWithGoogle();
            }}
          />
        </header>
        <div className="Home__container">
          <h1 className="Home__title">No nonsense investment tracking.</h1>
          <div className="Home__subtitle">
            Add the investments you own and track their growth. That&rsquo;s it.
            <div className="Home__subtitle__extra">
              (Oh, and it’s 100% free)
            </div>
          </div>
        </div>
      </section>
      <section className="Home__body">
        <ul className="Home__bullets">
          <li className="Home__bullets__item">
            <Checkmark colour="#2CCDCD" />
            <p>Track your portfolio performance over time</p>
          </li>
          <li className="Home__bullets__item">
            <Checkmark colour="#21D288" />
            <p>Add your stocks, mutual funds, and ETFs</p>
          </li>
          <li className="Home__bullets__item">
            <Checkmark colour="#60DF40" />
            <p>Manage buy and sell transactions</p>
          </li>
        </ul>
        <div className="Home__preview">
          <img
            src={screenshot}
            srcSet={`${screenshot} 1x, ${screenshot2x} 2x`}
            alt="App Preview"
          />
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
