import { fx } from 'money';

import { openExApiUrl } from './apiPaths';
import reduxStore from './reduxStore';

fetch(openExApiUrl)
  .then(res => res.json())
  .then(({ rates, base }) => {
    fx.rates = rates;
    fx.base = base;
  });

const convertCurrency = ({ amount, from = 'USD' }) => {
  const to = reduxStore.getState().settings.currency;
  from = from.toUpperCase();
  if (from === to) return amount;
  return fx.convert(amount, { from, to });
};

export const supportedCurrencies = ['USD', 'EUR', 'JPY', 'GBP', 'CAD', 'CHF'];

export default convertCurrency;
