import React from 'react';

import Header from '../components/Header';
import StockList from '../components/StockList';
import { HOLDINGS_VIEW } from '../helpers/constants';
import Footer from 'components/Footer';

const columnTitles = ['Symbol', 'Name', 'Avg Price', 'Quantity', 'Book Value'];

const Holdings = () => (
  <div className="App--signedIn">
    <Header />
    <StockList
      allowTransactionsView
      columnTitles={columnTitles}
      view={HOLDINGS_VIEW}
    />
    <Footer inApp />
  </div>
);

export default Holdings;
