import 'normalize.css';
import './App.css';

import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';

import Modal from 'components/Modal';
import LoadingSpinner from 'components/LoadingSpinner';
import Notifications from 'components/Notifications';
import { getSettings } from 'actions';
import { trackPage } from 'helpers/analytics';
import {
  USER_AUTH_FAILED,
  MODAL_ADD_TRANSACTION,
  MODAL_SELL_TRANSACTION,
} from 'helpers/constants';
import { needsAuth, authPath } from 'helpers/paths';

const mapStateToProps = state => ({ user: state.user });
const mapDispatchToProps = dispatch => ({
  getSettings: userId => dispatch(getSettings(userId)),
});

const handleAuthenticationRouting = ({ user, location, history }) => {
  if (user.status === USER_AUTH_FAILED && needsAuth(location.pathname)) {
    history.replace(authPath);
    return true;
  }
  return false;
};

class App extends React.Component {
  componentDidUpdate = prevProps => {
    const { user, getSettings } = this.props;
    if (user && user.uid) getSettings(user.uid);

    // record pageview in analytics when page changes
    if (this.props.location !== prevProps.location) {
      trackPage(this.props.location);
    }
  };

  render() {
    const { children, user, location, history } = this.props;

    if (handleAuthenticationRouting({ user, location, history })) return false;

    // redirect to performance if user already authenticated
    if (location.pathname === authPath && user && user.uid)
      history.replace('/performance');

    if (user.status === 'pending') {
      return <LoadingSpinner />;
    }

    return (
      <div className="App">
        <TransitionGroup>
          <Route
            path="/holdings/add"
            render={() => (
              <Modal type={MODAL_ADD_TRANSACTION} userID={user.uid} />
            )}
          />
          <Route
            path="/holdings/sell"
            render={() => (
              <Modal type={MODAL_SELL_TRANSACTION} userID={user.uid} />
            )}
          />
        </TransitionGroup>

        <Route
          path="/holdings/:type"
          children={({ match }) => (
            <div className="App__body">
              <Notifications />
              {children}
            </div>
          )}
        />
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
